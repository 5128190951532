export const environment = {
  production: false,
  oktaClientId: '0oa12ypkp7uYjOlLR0h8',
  oktaIssuer: 'https://id-sbx.resmed.com/oauth2/default',
  surveyApiUri:
    'https://survey-api.sleep-concierge-apac-qa.dht.live/survey/response/list',
  switchboardUri:
    'https://survey-api.sleep-concierge-apac-qa.dht.live/survey/test/switchboard',
  mquiDomain: 'mobilequestionsui.sleep-concierge-apac-qa.dht.live',
};
